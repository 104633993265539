import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonies = [
  "Stefania: ¡Perfecto! Muy buen maestro, explica súper bien!!",
  "Francesco: ¡Perfecto! Muy buenas clases. Franco se maneja en los contenidos y es muy claro a la hora de explicar. Lo recomiendo",
  "Julián: ¡Perfecto! Todo perfecto, lo recomiendo",
  "Ai: He is very knowledgeable and I am looking forward to taking next sessions with him.",
  "Areli S: La primera clase me ha gustado bastante, con buena preparación, excelentes explicaciones y sencillas, con mucha paciencia."
];

const TestimoniesSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="py-16 bg-[#001f3f]" id="testimonies">
      <h2 className="text-4xl font-extrabold text-[#00E5BC] text-center mb-8">
        Testimonies
      </h2>
      <p className="text-base md:text-lg text-[#AFB3B7] mb-12 text-center px-4 md:px-16 lg:px-32 xl:px-48">
        I am passionate about sharing my knowledge and believe that having someone skilled in teaching is a valuable asset to any team. I offer programming classes through my <a href="https://www.superprof.mx/orientacion-asesoramiento-para-estudiantes-informatica-aprende-crear-aplicaciones-con-ruby-rails-javascript-css-html.html" className="underline hover:text-[#00E5BC]">Superproof</a> account, where you can see testimonials from my students.
      </p>
      <Slider {...settings}>
        {testimonies.map((testimony, index) => (
          <div key={index} className="text-white text-center p-6 rounded-lg mx-4"> {/*Ajustes en Develop*/}
            <p className="text-base md:text-xl leading-relaxed">
              "{testimony}"
            </p>
          </div>
        ))}
      </Slider>
    </section>
  );
};

export default TestimoniesSection;
